
import {
  reactive,
  toRefs,
  computed,
  ref,
  watch,
  onMounted,
  watchEffect,
  defineComponent,
} from "vue";
const __default__ = defineComponent({
  name: "icon",
  props: ["title", "icon", "fontSize", "color"],
  setup(props: { icon: string | string[] }) {
    const _this = reactive({
      iconName: computed(() => {
        if (props.icon.includes("el-icon")) {
          return props.icon;
        }
        return `#icon-${props.icon}`;
      }),
    });

    const isElementUiIcon = ref(false);
    watchEffect(() => {
      isElementUiIcon.value = _this.iconName.includes("el-icon");
    });

    return {
      ...toRefs(_this),
      isElementUiIcon,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5d265763": (_ctx.fontSize),
  "2e857460": (_ctx.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__