import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory,
} from "vue-router";
import { BackItem } from "./modules/backend";
import { useAnimateStore } from "@/stores/animate";
import { useUserStore } from "@/stores/user";
import { message } from "ant-design-vue";
import { Action, ElMessageBox, ElMessage } from "element-plus";
import axios from "axios";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/backend",
    name: "backend",
    children: BackItem,
    component: () => import("@/Views/Backend/index.vue"),
  },
  {
    // 匹配所有路径  vue2使用*   vue3使用/:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
    path: "/:pathMatch(.*)",
    component: () => import("@/Views/404/index.vue"),
  },
  //登录界面
  {
    path: "/login",
    name: "login",

    component: () => import("@/Views/Login.vue")
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
//使用路由拦截进行动画切换
let timer: any = null;
let timermon: any = null;

router.beforeEach((to, from, next) => {
  const animatestore = useAnimateStore && useAnimateStore();
  animatestore && animatestore.SetAnimate(false);
  timer && clearTimeout(timer);
  timermon && clearTimeout(timermon);

  timer = setTimeout(() => {
    next();
    timermon = setTimeout(() => {
      animatestore && animatestore.SetAnimate(true);
    }, 500);
  }, 200);

  if (to.path === "/login") {
    next()
  } else {
    // 如果没有token，强制跳转到登录页面；如果有，则判断token时间是否过期
    if (sessionStorage.getItem("token") == null || sessionStorage.getItem("token") == "") {
      next('/login')
    } else {
        next();
      }
    }

  if(to.path.includes("/backend")){
    if(sessionStorage.getItem("isAdmin") == "true"){
      next()
    }else{
      //router.push('/home')
      router.push(from.path)
      //ElMessage("不是管理员")
    }
  }
  }
);

export default router;
