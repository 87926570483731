import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cb21692"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = {
  class: "svg-icon",
  "aria-hidden": "true"
}
const _hoisted_3 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isElementUiIcon)
    ? (_openBlock(), _createElementBlock("i", {
        key: 0,
        class: _normalizeClass(_ctx.iconName)
      }, null, 2))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        title: _ctx.title
      }, [
        (_openBlock(), _createElementBlock("svg", _hoisted_2, [
          _createElementVNode("use", { "xlink:href": _ctx.iconName }, null, 8, _hoisted_3)
        ]))
      ], 8, _hoisted_1))
}