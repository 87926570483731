
import { RouteRecordRaw } from "vue-router";

export const BackItem: Array<RouteRecordRaw> = [
    {
        path: "VideoConfig",
        name: "VideoConfig",
        meta: {
            title: "监控配置",
        },
        component: () => import("@/Views/Backend/Video/VideoConfig.vue"),
    },
    {
        path: "CameraConfig",
        name: "CameraConfig",
        meta: {
            title: "摄像头配置",
        },
        component: () => import("@/Views/Backend/Video/CameraConfig.vue"),
    },
    {
        path: "WalkConfig",
        name: "WalkConfig",
        meta: {
            title: "漫游巡检配置",
        },
        component: () => import("@/Views/Backend/Scene/WalkConfig.vue"),
    },
    {
        path: "ModelConfig",
        name: "ModelConfig",
        meta: {
            title: "模型配置",
        },
        component: () => import("@/Views/Backend/Scene/ModelConfig.vue"),
    },
    {
        path: "BuildingConfig",
        name: "BuildingConfig",
        meta: {
            title: "楼宇配置",
        },
        component: () => import("@/Views/Backend/Scene/BuildingConfig.vue"),
    },
    {
        path: "ParkIntroductionConfig",
        name: "ParkIntroductionConfig",
        meta: {
            title: "园区介绍配置",
        },
        component: () => import("@/Views/Backend/Scene/ParkIntroductionConfig.vue"),
    },
    {
        path: "InvestMentConfig",
        name: "InvestMentConfig",
        meta: {
            title: "招商信息配置",
        },
        component: () => import("@/Views/Backend/Invest/InvestmentList.vue"),
    },
    {
        path: "ParkingConfig",
        name: "ParkingConfig",
        meta: {
            title: "停车位配置",
        },
        component: () => import("@/Views/Backend/Parking/ParkingConfig.vue"),
    },
    {
        path: "RadioConfig",
        name: "RadioConfig",
        meta: {
            title: "语音配置",
        },
        component: () => import("@/Views/Backend/Scene/RadioConfig.vue"),
    },
    {
        path: "DevComm",
        name: "DevComm",
        meta: {
            title: "设备通信",
        },
        component: () => import("@/Views/Backend/Device/DevComm.vue"),
    },
    {
        path: "VarDatabase",
        name: "VarDatabase",
        meta: {
            title: "变量数据库",
        },
        component: () => import("@/Views/Backend/VarDataBase/VarDatabase.vue"),
    },
    {
        path: "KafkaConfig",
        name: "KafkaConfig",
        meta: {
          title: "Kafka中间件",
        },
        component: () => import("@/Views/Backend/Kafka/KafkaConfig.vue"),
    },
    // {
    //     path: "ServeConfig",
    //     name: "ServeConfig",
    //     meta: {
    //       title: "服务器配置",
    //     },
    //     component: () => import("@/Views/Backend/Serve/ServeConfig.vue"),
    // },
    {
        path: "AlarmIoConfig",
        name: "AlarmIoConfig",
        meta: {
          title: "报警点配置",
        },
        component: () => import("@/Views/Backend/Alarm/AlarmIoConfig.vue"),
    },
    {
        path: "VisitorDataBaseConfig",
        name: "VisitorDataBaseConfig",
        meta: {
            title: "访客数据库",
        },
        component: () => import("@/Views/Backend/VisitorMgr/VisitorDatabaseConfig.vue"),
    },
    {
        path: "ItemCfg",
        name: "ItemCfg",
        meta: {
            title: "报修管理",
        },
        component: () => import("@/Views/Backend/AssetsMgr/ItemConfig.vue"),
    },
    {
        path: "InventoryCfg",
        name: "InventoryCfg",
        meta: {
            title: "库存管理",
        },
        component: () => import("@/Views/Backend/AssetsMgr/InventoryConfig.vue"),
    },
    {
        path: "OrderCfg",
        name: "OrderCfg",
        meta: {
            title: "预约管理",
        },
        component: () => import("@/Views/Backend/Order/RouteOrderCfg.vue"),
    },
    {
        path: "EquipTableConfig",
        name: "EquipTableConfig",
        meta: {
            title: "设备表配置",
        },
        component: () =>
            import("@/Views/Backend/Equipment/EquipTableConfig.vue"),
    },
    {
        path: "EquipFieldConfig",
        name: "EquipFieldConfig",
        meta: {
            title: "差异化设备表配置",
        },
        component: () =>
            import("@/Views/Backend/Equipment/EquipFieldConfig.vue"),
    },
    {
        path: "BasicEquipConfig",
        name: "BasicEquipConfig",
        meta: {
            title: "设备属性配置",
        },
        component: () =>
            import("@/Views/Backend/Equipment/BasicEquipConfig.vue"),
    },
    {
        path: "EquipControlConfig",
        name: "EquipControlConfig",
        meta: {
            title: "设备控制配置",
        },
        component: () =>
            import("@/Views/Backend/Equipment/EquipControlConfig.vue"),
    },
    {
        path: "AIPlatformCfg",
        name: "AIPlatformCfg",
        meta: {
            title: "平台接入",
        },
        component: () =>
            import("@/Views/Backend/AI/AIPlatformCfg.vue"),
    },
    {
        path: "VehicleGateDevice",
        name: "VehicleGateDevice",
        meta: {
            title: "道闸设备管理",
        },
        component: () =>
            import("@/Views/Backend/Vehicle/VehicleGateDevice.vue"),
    },
    {
        path: "VehicleGateMonitor",
        name: "VehicleGateMonitor",
        meta: {
            title: "道闸数据监测",
        },
        component: () =>
            import("@/Views/Backend/Vehicle/VehicleGateMonitor.vue"),
    },
    {
        path: "User",
        name: "User",
        meta: {
            title: "用户管理",
        },
        component: () =>
            import("@/Views/Backend/SysCfg/User.vue"),
    },
    {
        path: "UserDetail",
        name: "UserDetail",
        meta: {
            title: "用户界面",
        },
        component: () =>
            import("@/Views/Backend/SysCfg/UserDetail.vue"),
    },
    {
        path: "PassRecord",
        name: "PassRecord",
        meta: {
            title: "无感通行记录",
        },
        component: () =>
            import("@/Views/Backend/Pass/PassRecord.vue"),
    },
    {
        path: "EleMeterConfig",
        name: "EleMeterConfig",
        meta: {
            title: "电表配置",
        },
        component: () =>
            import("@/Views/Backend/Energy/EleMeterConfig.vue"),
    },
    {
        path: "EnergyReport",
        name: "EnergyReport",
        meta: {
            title: "用能月报",
        },
        component: () =>
            import("@/Views/Backend/Energy/EnergyReport.vue"),
    },
    {
        path: "Suggestion",
        name: "Suggestion",
        meta: {
            title: "问题清单",
        },
        component: () =>
            import("@/Views/Backend/Suggest/Suggestion.vue"),
    },
];
