
// @ts-check
import { defineStore, acceptHMRUpdate } from "pinia";

/**
 * Simulate a login
 * @param {string} a
 * @param {string} p
 */

export const useDialogStore = defineStore({
  id: "Dialog",
  state: () => ({
    dialogVisible: false,
    dialogCarFlowVisible: false,
    dialogCarillegalVisible: false,
    dialogVisibleEmploy: true,
    dialogVisibleGuest: false,
    XY: [0, 0],
    LouInfo: {} as any,
    title: "详细信息",
    // 内容
    content: [],
    column: [],
  }),
  actions: {
    setDialogVisible(dialogVisible: boolean) {
      this.$patch({
        dialogVisible,
      });
    },
    setDialogVisibleEmploy(dialogVisibleEmploy: boolean) {
      this.$patch({
        dialogVisibleEmploy,
      });
    },
    setDialogVisibleGuest(dialogVisibleGuest: boolean) {
      this.$patch({
        dialogVisibleGuest,
      });
    },
    setXY(XY: Array<any>) {
      this.$patch({
        XY,
      });
    },
    async setDialogInfo(LouInfo: any) {
      this.$patch({
        LouInfo,
      });
    },
    setTitle(title: string) {
      this.$patch({
        title,
      });
    },
    setContent(content: Array<any>) {
      console.log(content, "setContent");
      this.$patch({
        content,
      });
    },
    setColumn(column: Array<any>) {
      console.log(column, "setContent");
      this.$patch({
        column,
      });
    },
  },
});
