import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import { createPinia } from "pinia";
import "element-plus/dist/index.css";

import "ant-design-vue/dist/antd.css";
import "qweather-icons/font/qweather-icons.css";
import "@/assets/scss/normalize.scss";
import "@/assets/scss/global.scss";
import "@/assets/scss/rest.scss";
import "animate.css";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from '@element-plus/icons-vue' // 全局引入图标

import Antd from "ant-design-vue";
import Icon from "@/components/Icon/index.vue";
import "@/assets/iconfont/iconfont.js";
// 标题组件
import Lease_title from "@/components/Lease_title/index.vue";
// 弹窗组件
import Dialog from "@/components/Dialog/index.vue";
import V3count from "@/components/V3count/vue-countTo.vue";
import "echarts-liquidfill";
import { useDialogStore } from "@/stores/dialog";
//兄弟组件通信组件
import mitt, { Emitter } from 'mitt';
import "lib-flexible-computer";
// 定义类型别名，因全局使用并且需要自定义事件名称，所以使用索引签名定义内容
type Events = {
  [propName: string]: any;
};
//定义
const Mit : Emitter<Events> = mitt<Events>();
//全局声明
declare module 'vue' {
    export interface ComponentCustomProperties{
        $Bus: typeof Mit
    }
}
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
//vue挂载全局api                                            
app.config.globalProperties.$Bus = Mit
app.config.globalProperties.$platFormId1 = 1 
app
  .use(ElementPlus)
  .use(Antd)
  .use(createPinia())
  .use(router)
  .component("Icon", Icon)
  .component("Dialog", Dialog)
  .component("Lease_title", Lease_title)
  .component("V3count", V3count)
  .directive("drag", {
    mounted: function (el: any, binding: any, vnode: any) {
      const value = binding.value;
      const moveContainer =
        (value && value.container && document.querySelector(value.container)) ||
        document.querySelector("#map3dContainer") ||
        document.body;
      const selector =
        (value && value.selector && el.querySelector(value.selector)) ||
        el.querySelector(".drag-el") ||
        el;
      if (selector !== el) {
        selector.classList.add("ls-draggable");
      }
      selector.onmousedown = function (e: any) {
        el.style.transition = "none";
        const disx = e.clientX - el.offsetLeft;
        const disy = e.clientY - el.offsetTop;
        const mw = moveContainer.offsetWidth;
        const mh = moveContainer.offsetHeight;
        const ew = el.offsetWidth;
        const eh = el.offsetHeight;
        // 点击后将当前组件置顶


        moveContainer.onmousemove = function (e: any) {
          let left = e.clientX - disx;
          let top = e.clientY - disy;
          if (left < 0) {
            left = 0;
          }
          if (top < 0) {
            top = 0;
          }
          if (left > mw - ew) {
            left = mw - ew;
          }
          if (top > mh - eh) {
            top = mh - eh;
          }
          el.style.left = left + "px";
          el.style.top = top + "px";
        };
        moveContainer.onmouseup = function (e: any) {
          const left = e.clientX - disx;
          const top = e.clientY - disy;
          moveContainer.onmousemove = moveContainer.onmouseup = null;
          useDialogStore().setXY([left, top]);
          el.style.transition = "all 0.3s";
        };
      };
    },
  })
  .mount("#app");
